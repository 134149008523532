//
// _menu.scss
//

.metismenu {
    margin: 0;

    li {
        display: block;
        width: 100%;
    }

    .mm-collapse {
        display: none;

        &:not(.mm-show) {
            display: none;
        }

        &.mm-show {
            display: block;
        }
    }

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: 0.35s;
        transition-property: height, visibility;
    }
}

.vertical-menu {
    width: $sidebar-width;
    z-index: 1001;
    background: $sidebar-bg;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: $header-height;
    box-shadow: $box-shadow;
}

.main-content {
    margin-left: $sidebar-width;
    overflow: hidden;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}

#sidebar-menu {
    padding: 10px 0 30px 0;

    .mm-active {
        > .has-arrow {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .has-arrow {
        &:after {
            content: "\F0140";
            font-family: "Material Design Icons";
            display: block;
            float: right;
            transition: transform 0.2s;
            font-size: 1rem;
        }
    }

    ul {
        li {
            a {
                display: block;
                padding: 0.625rem 1.5rem;
                color: $sidebar-menu-item-color;
                position: relative;
                font-size: 0.98rem;
                transition: all 0.4s;
                font-family: $font-family-secondary;

                i {
                    display: inline-block;
                    min-width: 1.5rem;
                    font-size: 1.1rem;
                    line-height: 1.40625rem;
                    vertical-align: middle;
                    color: $sidebar-menu-item-icon-color;
                    transition: all 0.4s;
                    opacity: 0.75;
                }

                &:hover {
                    color: $sidebar-menu-item-hover-color;

                    i {
                        color: $sidebar-menu-item-hover-color;
                    }
                }
            }

            .badge {
                margin-top: 4px;
            }

            ul.sub-menu {
                padding: 0;

                li {
                    a {
                        padding: 0.4rem 1.5rem 0.4rem 3.2rem;
                        font-size: 14px;
                        color: $sidebar-menu-sub-item-color;
                    }

                    ul.sub-menu {
                        padding: 0;

                        li {
                            a {
                                padding: 0.4rem 1.5rem 0.4rem 4.2rem;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-title {
    padding: 12px 20px !important;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: $sidebar-menu-item-icon-color;
    font-weight: $font-weight-semibold;
    font-family: $font-family-secondary;
    opacity: 0.5;
}

.mm-active {
    color: $sidebar-menu-item-active-color !important;
    > a {
        color: $sidebar-menu-item-active-color !important;
        i {
            color: $sidebar-menu-item-active-color !important;
        }
    }
    > i {
        color: $sidebar-menu-item-active-color !important;
    }
    .active {
        color: $sidebar-menu-item-active-color !important;

        i {
            color: $sidebar-menu-item-active-color !important;
        }
    }
}

@media (max-width: 992px) {
    .vertical-menu {
        display: none;
    }

    .main-content {
        margin-left: 0 !important;
    }

    body.sidebar-enable {
        .vertical-menu {
            display: block;
        }
    }
}

// Enlarge menu
.vertical-collpsed {
    .main-content {
        margin-left: $sidebar-collapsed-width;
    }

    .navbar-brand-box {
        width: $sidebar-collapsed-width !important;
        padding: 0;
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: block;
        }
    }

    // Side menu
    .vertical-menu {
        position: absolute;
        width: $sidebar-collapsed-width !important;
        z-index: 5;

        .simplebar-mask,
        .simplebar-content-wrapper {
            overflow: visible !important;
        }

        .simplebar-scrollbar {
            display: none !important;
        }

        .simplebar-offset {
            bottom: 0 !important;
        }

        // Sidebar Menu
        #sidebar-menu {
            .menu-title,
            .badge,
            .collapse.in {
                display: none !important;
            }

            .nav.collapse {
                height: inherit !important;
            }

            .has-arrow {
                &:after {
                    display: none;
                }
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;

                    > a {
                        padding: 15px 20px;
                        min-height: 55px;
                        transition: none;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $sidebar-menu-item-hover-color;
                        }

                        i {
                            font-size: 20px;
                            margin-left: 4px;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                        }
                    }

                    &:hover {
                        > a {
                            position: relative;
                            width: calc(190px + #{$sidebar-collapsed-width});
                            color: $primary;
                            background-color: darken($sidebar-bg, 4%);
                            transition: none;

                            i {
                                color: $primary;
                            }

                            span {
                                display: inline;
                            }
                        }

                        > ul {
                            display: block;
                            left: $sidebar-collapsed-width;
                            position: absolute;
                            width: 190px;
                            height: auto !important;
                            box-shadow: 3px 5px 12px -4px rgba(18, 19, 21, 0.1);

                            ul {
                                box-shadow: 3px 5px 12px -4px rgba(18, 19, 21, 0.1);
                            }

                            a {
                                box-shadow: none;
                                padding: 8px 20px;
                                position: relative;
                                width: 190px;
                                z-index: 6;
                                color: $sidebar-menu-sub-item-color;

                                &:hover {
                                    color: $sidebar-menu-item-hover-color;
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $sidebar-bg;

                    li {
                        &:hover {
                            > ul {
                                display: block;
                                left: 190px;
                                height: auto !important;
                                margin-top: -36px;
                                position: absolute;
                                width: 190px;
                            }
                        }

                        > a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }

                    li.active {
                        a {
                            color: $gray-100;
                        }
                    }
                }
            }
        }
    }
}

body[data-sidebar="dark"] {
    .vertical-menu {
        background: $sidebar-dark-bg;
    }

    #sidebar-menu {
        ul {
            li {
                a {
                    color: $sidebar-dark-menu-item-color;

                    i {
                        color: $sidebar-dark-menu-item-icon-color;
                    }

                    &:hover {
                        color: $sidebar-dark-menu-item-hover-color;

                        i {
                            color: $sidebar-dark-menu-item-hover-color;
                        }
                    }
                }

                ul.sub-menu {
                    li {
                        a {
                            color: $sidebar-dark-menu-sub-item-color;

                            &:hover {
                                color: $sidebar-dark-menu-item-hover-color;
                            }
                        }
                    }
                }
            }
        }
    }
    // Enlarge menu
    &.vertical-collpsed {
        min-height: 1400px;

        // Side menu
        .vertical-menu {
            // Sidebar Menu
            #sidebar-menu {
                > ul {
                    > li {
                        &:hover {
                            > a {
                                background: lighten($sidebar-dark-bg, 2%);
                                color: $sidebar-dark-menu-item-hover-color;
                                i {
                                    color: $sidebar-dark-menu-item-hover-color;
                                }
                            }

                            > ul {
                                a {
                                    color: $sidebar-dark-menu-sub-item-color;
                                    &:hover {
                                        color: $sidebar-menu-item-hover-color;
                                    }
                                }
                            }
                        }
                    }

                    ul {
                        background-color: lighten($card-bg, 1%);
                    }
                }

                ul {
                    > li {
                        > a {
                            &.mm-active {
                                color: $sidebar-dark-menu-item-active-color !important;
                                background-color: $sidebar-dark-menu-item-active-bg !important;
                            }
                        }
                    }

                    li {
                        li {
                            &.mm-active,
                            &.active {
                                > a {
                                    color: $sidebar-menu-item-active-color !important;
                                }
                            }

                            a {
                                &.mm-active,
                                &.active {
                                    color: $sidebar-menu-item-active-color !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .mm-active {
        color: $sidebar-dark-menu-item-active-color !important;
        > a {
            color: $sidebar-dark-menu-item-active-color !important;
            i {
                color: $sidebar-dark-menu-item-active-color !important;
            }
        }
        > i {
            color: $sidebar-dark-menu-item-active-color !important;
        }
        .active {
            color: $sidebar-dark-menu-item-active-color !important;

            i {
                color: $sidebar-dark-menu-item-active-color !important;
            }
        }
    }

    .menu-title {
        color: $sidebar-dark-menu-item-icon-color;
    }
}

body[data-layout="horizontal"] {
    .main-content {
        margin-left: 0 !important;
    }
}

// Compact Sidebar

body[data-sidebar-size="small"] {
    .navbar-brand-box {
        width: $sidebar-width-sm;

        @media (max-width: 992px) {
            width: auto;
        }
    }

    .vertical-menu {
        width: $sidebar-width-sm;
        text-align: center;

        .has-arrow:after,
        .badge {
            display: none !important;
        }
    }
    .main-content {
        margin-left: $sidebar-width-sm;
    }
    .footer {
        left: $sidebar-width-sm;
        @media (max-width: 991px) {
            left: 0;
        }
    }

    #sidebar-menu {
        ul li {
            &.menu-title {
                background-color: lighten($sidebar-dark-bg, 2%);
            }
            a {
                i {
                    display: block;
                }
            }
            ul.sub-menu {
                li {
                    a {
                        padding-left: 1.5rem;
                    }

                    ul.sub-menu {
                        li {
                            a {
                                padding-left: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
    &.vertical-collpsed {
        .main-content {
            margin-left: $sidebar-collapsed-width;
        }
        .vertical-menu {
            #sidebar-menu {
                text-align: left;
                > ul {
                    > li {
                        > a {
                            i {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
        .footer {
            left: $sidebar-collapsed-width;
        }
    }
}
// .MuiBox-root,.first-card-header,.first-card-body,.remaining-card-header, .remaining-card-body, .MuiAccordion-region, .css-8atqhb, .MuiAccordion-heading, .css-cy7rkm-MuiAccordion-heading, .css-1fxc493-MuiPaper-root-MuiAccordion-root{
//     background-color: $light-score-card-bg !important;
// }
.right-panel-over-accordian{
    background-color: $light-score-card-bg !important;
}
.right-panel-over-accordian-summary{
    background-color: $light-score-card-bg !important;
}
// .first-card-body{
//     background-color: $light-score-card-bg !important;
// }

// .MuiButtonBase-root, .MuiAccordionSummary-root, .Mui-expanded .MuiAccordionSummary-gutters, .css-1dfengi{
//     background-color: $light-score-card-bg !important;
// }

.market-type-table {
    &.table {
      &.table-bordered {
        &.table-striped {
          tbody {
            tr:nth-child(odd) {
                background-color: #d4edda !important; // Light green
              }
    
              tr:nth-child(even) {
                background-color: #ffffff !important; // White
              }
            // tr{
            //     // &:hover{
            //     //     background-color: $dark-score-card-bg !important; // Light green
            //     // }
            // }
            td{
                color: $light-font-color !important
            }
          }
        }
      }
    }
  }

  .color-white {
    background-color: $light-body-bg !important;
  }
  .modal-header-title{
    color: #000000 !important
}
.ball-white {
    background-color: white;
  }

body[data-theme="dark"]{
    .css-1wqf3nl-MuiAccordionSummary-expandIconWrapper{
        color: $dark-font-color !important;
    }
    .ball-white {
        background-color: $vertical-menu-dark-bg !important;
      }
      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-q7mezt {
        color: $dark-font-color !important ; // Change to your desired color
    }
    .vertical-menu{
        background-color: $vertical-menu-dark-bg;
        // color: aqua;
    }
    #sidebar-menu {
        padding: 10px 0 30px 0;
        .mm-active {
            color: $sidebar-dark-menu-item-active-color !important;
            > .has-arrow {
                &:after {
                    transform: rotate(-180deg);
                }
            }
            ul{
                li{
                    a:active{
                        color: $sidebar-dark-menu-item-active-color !important;
                    }
                    a:hover{
                        color: $sidebar-dark-menu-item-hover-color !important;
                    }
                }
            }
        }
        .has-arrow {
            &:after {
                content: "\F0140";
                font-family: "Material Design Icons";
                display: block;
                float: right;
                transition: transform 0.2s;
                font-size: 1rem;
            }
        }
    
        ul {
            li {
                a {
                    display: block;
                    padding: 0.625rem 1.5rem;
                    color: $sidebar-dark-menu-item-color;
                    position: relative;
                    font-size: 0.98rem;
                    transition: all 0.4s;
                    font-family: $font-family-secondary;
    
                    i {
                        display: inline-block;
                        min-width: 1.5rem;
                        font-size: 1.1rem;
                        line-height: 1.40625rem;
                        vertical-align: middle;
                        color: $sidebar-dark-menu-item-icon-color;
                        transition: all 0.4s;
                        opacity: 0.75;
                    }
    
                    &:hover {
                        color: $sidebar-dark-menu-item-hover-color;
    
                        i {
                            color: $sidebar-dark-menu-item-hover-color;
                        }
                    }
                    &:active {
                        color: $sidebar-dark-menu-item-active-color;
                    }
                }
    
                .badge {
                    margin-top: 4px;
                }
    
                ul.sub-menu {
                    padding: 0;
    
                    li {
                        a {
                            padding: 0.4rem 1.5rem 0.4rem 3.2rem;
                            font-size: 14px;
                            color: $sidebar-dark-menu-sub-item-color;
                        }
    
                        ul.sub-menu {
                            padding: 0;
    
                            li {
                                a {
                                    padding: 0.4rem 1.5rem 0.4rem 4.2rem;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .vertical-menu {
        // Sidebar Menu
        #sidebar-menu {
            > ul {
                > li {
                    &:hover {
                        > a {
                            background: lighten($sidebar-dark-bg, 2%);
                            color: $sidebar-dark-menu-item-hover-color;
                            i {
                                color: $sidebar-dark-menu-item-hover-color;
                            }
                        }

                        > ul {
                            a {
                                color: $sidebar-dark-menu-sub-item-color;
                                &:hover {
                                    color: $sidebar-menu-item-hover-color;
                                }
                            }
                        }
                    }
                }

                // ul {
                //     background-color: lighten($card-bg, 1%);
                // }
            }

            ul {
                > li {
                    > a {
                        &.mm-active {
                            color: $sidebar-dark-menu-item-active-color !important;
                        }
                    }
                }

                li {
                    li {
                        &.mm-active,
                        &.active {
                            > a {
                                color: $sidebar-dark-menu-item-active-color !important;
                            }
                        }

                        a {
                            &.mm-active,
                            &.active {
                                color: $sidebar-dark-menu-item-active-color !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .mm-active {
        color: $sidebar-dark-menu-item-active-color !important;
        > a {
            color: $sidebar-dark-menu-item-active-color !important;
            i {
                color: $sidebar-dark-menu-item-active-color !important;
            }
        }
        > i {
            color: $sidebar-dark-menu-item-active-color !important;
        }
        .active {
            color: $sidebar-dark-menu-item-active-color !important;
    
            i {
                color: $sidebar-dark-menu-item-active-color !important;
            }
        }
    }
    .ri-menu-2-line,
    .ri-fullscreen-line,
    .mdi,
    .userName,
    .dropdown,
    .panel-name {
        color: $dark-body-color !important;
    }
    .over-modal-header-row{
        background-color: $dark-boxed-body-bg !important;
        color: $dark-body-color !important;
    }
    .over-stat {
        background-color: $dark-boxed-body-bg !important;
    }
    .over-modal-stat{
        background-color: $dark-boxed-body-bg !important;

    }
    .over-modal-player-stats{
        color: $dark-body-color !important;
    }
    .over-modal-stats-grid {
        border: 1px solid $dark-font-color !important;
    }
    .score-button{
        background-color: $dark-boxed-body-bg !important;
        color: $dark-body-color !important;
        border: 0.5px solid gray !important;
    }.decision-Button {
        // background-color: $gray-800 !important;
        color: $dark-font-color !important;
        border: none !important;
    }
    .btn-light{
        background-color: $gray-800 !important;
    }
    .first-card-body{
        background-color: $dark-score-card-bg !important;
    }
    .first-card-header, .remaining-card-body, .remaining-card-header{
        background-color: $dark-score-card-bg !important;
    }
    // .MuiBox-root,
    // .first-card-header,
    // .first-card-body,
    // .remaining-card-header,
    // .remaining-card-body,
    // .MuiAccordion-region,
    // .css-8atqhb,
    // .MuiAccordion-heading,
    // .css-cy7rkm-MuiAccordion-heading,
    // .css-1fxc493-MuiPaper-root-MuiAccordion-root {
    //     background-color: $dark-score-card-bg !important;
    //     color: $dark-body-color !important;
    // }
    // .css-sccedh{
    //     border: none
    // }
    // .MuiTypography-root,
    // .MuiTypography-body1,
    // .css-1i9gr79-MuiTypography-root,
    // .MuiTypography-root,
    // .MuiTypography-body2,
    // .css-199q83o-MuiTypography-root {
    //     color: $dark-body-color !important;
    // }
    .score-num-btn{
        color: green
    }
    // .MuiButtonBase-root,
    // .MuiAccordionSummary-root,
    // .Mui-expanded .MuiAccordionSummary-gutters,
    // .css-1dfengi {
    //     background-color: $dark-score-card-bg !important;
    // }
    .accordian-container{
        background-color: $dark-score-card-bg !important;
        color: $dark-font-color !important
    }
    // .css-6bl4gy-MuiTypography-root
    .accordian-text{
        font-family: 'Work Sans', sans-serif !important;
        color:$dark-font-color !important
    }
    // .partnership-tab-navigation{
    //     color: $dark-font-color !important;
    // }
    // .form-switch, .form-check-input:checked{
    //     background-color: #1A1D23 !important;
    // box-shadow: 
    // 0px 0px 0px 0px rgba(12, 14, 16, 0.9), 
    // 0px 0px 0px 0px rgba(40, 44, 54, 0.9), 
    // 0px -0px 0px 0px rgba(12, 14, 16, 0.2),
    // 0px 0px 0px 0px rgba(12, 14, 16, 0.2), 
    // -1px -1px 2px 0px rgba(12, 14, 16, 0.5) inset, /* Inner shadow (top-left) */
    // 1px 1px 2px 0px rgba(40, 44, 54, 0.3) inset;  /* Inner shadow (bottom-right) */
    // }

    .right-panel-over-accordian{
        background-color: $dark-score-card-bg !important;
        color: $dark-font-color !important
    } 
    .right-panel-over-accordian-summary{
        background-color: $dark-score-card-bg !important;
        color: $dark-font-color !important;
    }
    .bg-light{
        background-color: $dark-boxed-body-bg !important
    }

    .modal-header-title{
        color: $dark-font-color !important
    }

    .btn,
    .btn-light {
        color: $dark-font-color !important;
        border: none
    }

    .filter-dropdown {
        &__value-container {
            color: #FFFFFF !important; // Sets text color to white

            &--has-value {
                color: #FFFFFF !important; // Ensures color remains white when a value is selected
            }
        }
    }

    .filter-dropdown__multi-value__remove,
    .css-v7duua {
        color: $dark-body-bg !important;
    }

    .filter-dropdown {
        &__single-value {
            color: #FFFFFF !important; // Ensures the selected value text is white
        }
    }

    .filter-dropdown {
        &__menu {
            background-color: $light-body-bg !important; // Example: Set menu background to white
            color: $dark-score-body-bg !important; // Example: Set text color to black
        }
    }

    .filter-dropdown {
        &__option {
            &:hover {
                background-color: $dark-body-bg !important;
                color: $dark-font-color !important;
            }

            &--is-focused {
                background-color: $dark-body-bg !important;
                color: $dark-font-color !important;
            }

            &--is-selected {
                background-color: $dark-body-bg !important; // Darker blue for selected option
                color: $dark-font-color !important;
            }
        }
    }

    .clickable{
        color: $dark-font-color !important;
    }
    .filter-dropdown__single-value, .css-1dimb5e-singleValue{
        color: #505d69 !important;
    }
    .market-type-table {
        &.table {
            &.table-bordered {
            &.table-striped {
                tbody {
                // tr{
                //   background-color: $dark-score-card-bg !important; // White
                // }
                // tr:nth-child(odd) {
                //     background-color: $dark-score-card-bg !important; // Light green
                //   }
        
                //   tr:nth-child(even) {
                //     background-color: #ffffff !important; // White
                //   }
                td{
                    color: $dark-font-color !important
                }
                }
            }
            }
        }
        }

        .color-white {
        background-color: $dark-body-bg !important;
        }
        .list-group-item{
        background-color: unset !important;
        color: unset !important;
        }
        .ant-pagination {
            .ant-pagination-item {
              color: $dark-font-color;
              
              &:hover {
                color: #000000;
                background-color: $dark-font-color !important;
              }
              
              &.ant-pagination-item-active {
                color: #000000;
                background-color: $dark-font-color;
              }
            }
        }
      
    .ant-pagination-item-link{
    color: $dark-font-color !important;
    &:hover {
        color:#000000 !important;
        background-color: $dark-font-color !important;
      }
    }
    .ant-pagination-item-ellipsis{
        color: $dark-font-color !important
    }
      
}