
//
// accordion.scss
//

.custom-accordion {
    .card {
        + .card {
            margin-top: 0.5rem;
        }
    }

    a {
        &.collapsed {
            i.accor-plus-icon {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }

    .card-header{
        border-radius: 7px;
    }
}

// .MuiCollapse-wrapperInner, .MuiCollapse-vertical, .css-9vd5ud{
//     background-color: $light-score-card-bg;
// }

.custom-accordion-arrow{
    .card{
        border: 1px solid $border-color;
        box-shadow: none;
    }
    .card-header{
        padding-left: 45px;
        position: relative;

        .accor-arrow-icon{
            position: absolute;
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 16px;
            background-color: $primary;
            color: $white;
            border-radius: 50%;
            text-align: center;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    a {
        &.collapsed {
            i.accor-arrow-icon {
                &:before {
                    content: "\F0142";
                }
            }
        }
    }
}
.css-1fxc493-MuiPaper-root-MuiAccordion-root, .Mui-expanded{
    margin: 0px !important;
}

body[data-theme="dark"] {
    // .MuiCollapse-wrapperInner, .MuiCollapse-vertical, .css-9vd5ud{
    //     background-color: $dark-score-card-bg !important;
    // }
    .css-1fxc493-MuiPaper-root-MuiAccordion-root, .MuiAccordionSummary-root{
        border: none !important;
    }
}