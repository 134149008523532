.score-num-btn {
  background-color: "#2c2c3e" !important;
  color: "#fff" !important;
  border-radius: "50px" !important;
  box-shadow: "0 4px 10px rgba(0, 0, 0, 0.3)" !important;
  width: "100%" !important;
  height: "100%" !important;
  font-size: "20px" !important;
  font-weight: "bold" !important;
}

.cricket-score {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;

  .team {
    width: 45%;

    .team-name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .player {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      border-bottom: 1px solid #ddd;

      .player-name {
        font-weight: bold;
      }

      .player-stats {
        color: #555;
      }
    }
  }
}

.player-image{
  background-color: #d8d8d8 !important;
  border-radius: 2px;
}

.commentary-body{
  background-color: $light-score-body-bg !important;
}

.control-card {
  padding: 1rem !important;
  color: $light-font-color !important;
  border-radius: 8px;
  background-color: $light-score-card-bg !important;
  box-shadow: 
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}

.score-card {
  background-color: $light-score-card-bg !important;
  color: $light-font-color !important;
  box-shadow: 1px 1px 3px 0px rgba(178, 178, 178, 0.8980392157), -1px -1px 2px 0px rgba(255, 255, 255, 0.8980392157), 1px -1px 2px 0px rgba(178, 178, 178, 0.2), -1px 1px 2px 0px rgba(178, 178, 178, 0.2), -1px -1px 2px 0px rgba(178, 178, 178, 0.5019607843) inset, 1px 1px 2px 0px rgba(255, 255, 255, 0.3019607843) inset;
}

.score-card .box-card {
  background-color: $light-score-body-bg !important;
  // background-color: #ffff !important
}

// .score-card {
//   background-color: $light-score-card-bg !important;
//   color: $light-font-color !important;
//   box-shadow: 
//     1px 1px 3px 0px rgba(178, 178, 178, 0.9), 
//     -1px -1px 2px 0px rgba(255, 255, 255, 0.9), 
//     1px -1px 2px 0px rgba(178, 178, 178, 0.2), 
//     -1px 1px 2px 0px rgba(178, 178, 178, 0.2), 
//     -1px -1px 2px 0px rgba(178, 178, 178, 0.5) inset, 
//     1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
// }

// // Make sure .box-card has NO shadow
// .score-card .box-card {
//   background-color: $light-score-body-bg !important;
//   box-shadow: none !important; // This prevents it from inheriting shadows
// }

.box-top-left {
  top: -15px;
  left: -10px;
  width: 22px;
  height: 25px;
  border-radius: 50%;
}

.box-top-right {
  top: -15px;
  right: -10px;
  width: 22px;
  height: 25px;
  border-radius: 50%;
}

.box-bottom-right {
  bottom: -15px;
  right: -10px;
  width: 22px;
  height: 25px;
  border-radius: 50%;
}

.box-bottom-left {
  bottom: -15px;
  left: -10px;
  width: 22px;
  height: 25px;
  border-radius: 50%;
}

.box-left-semicircle {
  top: 50%;
  left: -10px;
  width: 22px;
  height: 25px;
  border-radius: 16px;
  transform: translateY(-50%);
}

.box-right-semicircle {
  top: 50%;
  right: -10px;
  width: 22px;
  height: 25px;
  border-radius: 16px;
  transform: translateY(-50%);
}

.card-title {
  color: $light-font-color !important
}

// .button-icon {
//   color: #ddd !important;
//   height: 16px !important;
// }

// .button-icon-lg {
//   color: #ddd !important;
//   height: 40px !important;
// }

body[data-theme="dark"] {
  .player-image {
    background-color: #1d2f4d !important;
    border-radius: 2px;
  }

  .control-card {
    background-color: $dark-score-card-bg !important;
    backdrop-filter: blur(80px); /* Adds a strong blur effect to the background */

    box-shadow: 
        0px 4px 32px 0px #00000080, /* Outer shadow - Large soft shadow */
        -1px -4px 8px 0px #00000040 inset; /* Inner shadow - Adds depth inside the element */

  }

  .score-card {
    background-color: $dark-score-card-bg !important;
    color: $dark-font-color !important;
    backdrop-filter: blur(80px);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.5),
              -1px -4px 8px 0px rgba(0, 0, 0, 0.25) inset;
  }
  
  .score-card .box-card {
    background-color: $dark-score-body-bg !important;
    // background-color: #1a2942 !important
  }

  .card-title {
    color: $dark-font-color !important
  }
}

.score-header-event-name {
  font-size: 14px;
  color: #333333;
}

.score-header-date-btn {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: 10px;
  background: #D9D9D9;
  border: 0.5px solid #CCCCCC;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.latency-btn{
  background: #D9D9D9;
  border: 0.5px solid #CCCCCC;
  padding: 6px;
  border-radius: 4px;
}

.score-header-navigation-btns {
  color: #00B400 !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D1D1D1;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 
    1px 1px 3px 0px #B2B2B2E5, 
    -1px -1px 2px 0px #FFFFFFE5, 
    1px -1px 2px 0px #B2B2B233, 
    -1px 1px 2px 0px #B2B2B233, 
    -1px -1px 2px 0px #B2B2B280 inset, 
    1px 1px 2px 0px #FFFFFF4D inset;
}

.scorecard-team-details{
  font-size: 26px;
}

.scorecard-striker-player-name{
  color: #000000 !important;
  font-size: 18px;
  // color: $dark-font-color;
}
.scorecard-nonstriker-player-name{
  color: #8a8a8a!important;
  font-size: 14px;
  // color: $dark-font-color;
}

.back-icon {
  color: #000000 !important;
}

.control-center-back-btn {
  color: #000000 !important;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
    &:hover{
      background: #E0E0E0 !important;
    }
}

.score-header-navigation-btns:hover {
  background: #E0E0E0;
  /* Lighter background on hover */
}

.css-6duc10-MuiAvatar-root {
  border-radius: 4px !important;
  background-color: #d8d8d8 !important;
}

.score-header-exit-btn {
  color: #fff !important;
  background-color: #E51717 !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}

.score-control-ball-btn {
  width: 100%;
  height: 100%;
  color: black !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F6F6F6;
  border: none;
  font-weight: bold;
  font-size: 36px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;

    &:hover,
    &:active,
    &.active {
    background-color: #1A1D23;
    color: #fff !important;
    box-shadow:
      0.89px 3.57px 3.57px 0px #B2B2B280 inset,
      0px 3.57px 3.57px 0px #FFFFFF4D inset;
    // box-shadow:
    //   3px 3px 8px 0px #0C0E10E5,
    //   -3px -3px 6px 0px #282C36E5,
    //   3px -3px 6px 0px #0C0E1033,
    //   -3px 3px 6px 0px #0C0E1033,
    //   -1px -1px 2px 0px #0C0E1080 inset,
    //   1px 1px 2px 0px #282C364D inset;
  }
  @media (max-width: 640px) {
    font-size: 24px;  // Reduce font size
    padding: 6px 6px;  // Adjust padding
    border-radius: 30px;  // Adjust border radius
  }
}

.score-control-ball-types-btns {
  width: 100%;
  height: 100%;
  background: #F5F5F5;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 900;
  font-size: 28px;
  border-radius: 40px;
  cursor: pointer;
  text-shadow: 
    0.66px 2.64px 2.64px rgba(0, 0, 0, 0.12),
    0px 2.64px 2.64px rgba(0, 0, 0, -0.75);
  transition: all 0.1s ease-in-out;

  /* Gradient text effect */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(180deg, #FFCD4C, #FFCD4C);

  /* Neumorphism effect */
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;

  &:hover,
  &:active,
  &.active {
    background: #FFCD4C;
    color: #F5F5F5 !important;
    box-shadow:
    0.89px 3.57px 3.57px 0px #B2B2B280 inset,
    0px 3.57px 3.57px 0px #FFFFFF4D inset;
    text-shadow: 
    0.66px 2.64px 2.64px rgba(0, 0, 0, 0.12),
    0px 2.64px 8.64px rgba(0, 0, 0, -0.75);
  }

  @media (max-width: 640px) {
    font-size: 18px;  // Reduce font size
    padding: 4px 6px;  // Adjust padding
    border-radius: 30px;  // Adjust border radius
  }
}
// .score-control-ball-types-btns {
//   width: 100%;
//   background: #f0f0f0;
//   border: none;
//   border-radius: 40px;
//   padding: 6px 12px;
//   position: relative;
//   cursor: pointer;
//   font-size: 30px;
//   font-weight: 800;
//   // font-family: 'Montserrat', sans-serif;
//   // letter-spacing: 8px;
  
//   // Text base
//   color: transparent;
//   background-clip: text;
//   -webkit-background-clip: text;
//   box-shadow:
//     1px 1px 3px 0px #B2B2B2E5,
//     -1px -1px 2px 0px #FFFFFFE5,
//     1px -1px 2px 0px #B2B2B233,
//     -1px 1px 2px 0px #B2B2B233,
//     -1px -1px 2px 0px #B2B2B280 inset,
//     1px 1px 2px 0px #FFFFFF4D inset;

//   // Main golden text with shadow effect
//   &::before {
//     content: attr(data-text);
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 100%;
//     transform: translate(-50%, -50%);
//     background: linear-gradient(135deg,
//       #FFCD4C 0%,
//       #FFCD4C 50%,
//       #e6b843 85%,
//       #d4a93d 100%
//     );
//     -webkit-background-clip: text;
//     background-clip: text;
//     color: transparent;
//     z-index: 2;
//     text-shadow: 
//       2px 2px 4px rgba(255, 255, 255, 0.3),
//       -2px -2px 4px rgba(0, 0, 0, 0.1);
// }
// &::after {
//   content: attr(data-text);
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 100%;
//   transform: translate(-50%, -50%);
//   background: linear-gradient(180deg,
//     rgba(255, 255, 255, 0.15) 0%,
//     rgba(0, 0, 0, 0.1) 100%
//   );
//   -webkit-background-clip: text;
//   background-clip: text;
//   color: transparent;
//   z-index: 1;
//   filter: blur(1px);
//   opacity: 0.8;
// }
// }

.score-wheel-btns {
  width: 94%;
  height: 80%;
  // color: linear-gradient(180deg, #FFCD4C -60.91%, #0000001F 221.1%) !important;
  color: #000000 !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border: none;
  font-weight: bold;
  font-size: 16px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // text-shadow: 
  //       0.66px 2.64px 2.64px #0000001F,
  //       0px 2.64px 2.64px #00000040; 
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;

  &:hover,
  &:active,
  &.active {
    background-color: #FFCD4C;
    color: #1A1D23 !important;
    box-shadow: 0px 3.57px 3.57px 0px #0000001F inset,
      0px 3.57px 3.57px 0px #00000040 inset;
  }
}

.score-control-action-ball-btns {
  width: 100%;
  height: 100%;
  background: #F5F5F5;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 900;
  font-size: 28px;
  border-radius: 40px;
  cursor: pointer;
  text-shadow: 
    0.66px 2.64px 2.64px rgba(0, 0, 0, 0.12),
    0px 2.64px 2.64px rgba(0, 0, 0, 0.25);
  transition: all 0.1s ease-in-out;

  /* Gradient text effect */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(180deg, #00B400, #008000);

  /* Neumorphism effect */
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;

  &:hover,
  &:active,
  &.active {
    background: #00B400;
    color: #F5F5F5 !important;
    box-shadow:
    0.89px 3.57px 3.57px 0px #B2B2B280 inset,
    0px 3.57px 3.57px 0px #FFFFFF4D inset;
    text-shadow: 
    0.66px 2.64px 2.64px rgba(0, 0, 0, 0.12),
    0px 2.64px 8.64px rgba(0, 0, 0, 0.25);
  }
  @media (max-width: 640px) {
    font-size: 18px;  // Reduce font size
    padding: 6px 6px;  // Adjust padding
    border-radius: 30px;  // Adjust border radius
  }
}

.score-control-action-btns {
  width: 100%;
  height: 100%;
  color: #00B400 !important;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border: none;
  font-weight: 500;
  font-size: 22px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;

  &:hover,
  &:active,
  &.active {
    background-color: #00B400;
    color: #F5F5F5 !important;
    box-shadow:
      0.89px 3.57px 3.57px 0px #B2B2B280 inset,
      0px 3.57px 3.57px 0px #FFFFFF4D inset;
  }
  @media (max-width: 640px) {
    font-size: 18px;  // Reduce font size
    padding: 6px 6px;  // Adjust padding
    border-radius: 30px;  // Adjust border radius
  }
}

.event-date{
  color: #000000 !important
}

.player-names {
  width: 100%;
  height: 100%;
  color: #333333 !important;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  background: #F5F5F5;
  border: none;
  // font-weight: 500;
  font-size: 16px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}

.score-control-close-btn {
  width: 100%;
  height: 100%;
  color: #000000 !important;
  padding: 8px 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border: none;
  font-size: 22px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}

.score-control-wicket-ball-btns {
  width: 100%;
  height: 100%;
  color: #FF4C4C !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border: none;
  font-weight: 600;
  font-size: 22px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;

  &:hover,
  &:active,
  &.active {
    background-color: #FF4C4C;
    color: white !important;
    box-shadow:
      0.89px 3.57px 3.57px 0px #B2B2B280 inset,
      0px 3.57px 3.57px 0px #FFFFFF4D inset;
  }
  @media (max-width: 640px) {
    font-size: 18px;  // Reduce font size
    padding: 6px 6px;  // Adjust padding
    border-radius: 30px;  // Adjust border radius
  }
}

.score-right-side-header {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 12px;
  color: white;
  background: linear-gradient(180deg, #3E119E -60.91%, #35127D 221.1%);
  box-shadow:
    0px 1px 5px 0px #180C40,
    0px 5.72px 5.72px 0px #574CD2 inset,
    0px -5.72px 5.72px 0px #281694 inset;
}

.vertical-line {
  border: 0.5px solid #F5F5F5;
  /* Black border */
  height: 250px;
  margin: 0px 10px;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}

.regular-overball {
  min-width: 30px;
  font-weight: 500;
  min-height: 30px;
  background: #E6E6E6;
  border-radius: 4px;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    /* Outer soft shadow */
    -1px -1px 2px 0px #FFFFFFE5,
    /* Outer highlight */
    1px -1px 2px 0px #B2B2B233,
    /* Outer subtle top-right shadow */
    -1px 1px 2px 0px #B2B2B233,
    /* Outer subtle bottom-left shadow */
    -1px -1px 2px 0px #B2B2B280 inset,
    /* Inner top-left shadow */
    1px 1px 2px 0px #FFFFFF4D inset;
  /* Inner bottom-right shadow */
}

.wicket-overball {
  min-width: 30px;
  font-weight: 500;
  min-height: 30px;
  padding: 0px 2px;
  background: #D90000;
  border-radius: 4px;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    /* Outer soft shadow */
    -1px -1px 2px 0px #FFFFFFE5,
    /* Outer highlight */
    1px -1px 2px 0px #B2B2B233,
    /* Outer subtle top-right shadow */
    -1px 1px 2px 0px #B2B2B233,
    /* Outer subtle bottom-left shadow */
    -1px -1px 2px 0px #B2B2B280 inset,
    /* Inner top-left shadow */
    1px 1px 2px 0px #FFFFFF4D inset;
  /* Inner bottom-right shadow */
}

.extra-overball {
  min-width: 30px;
  min-height: 30px;
  padding: 0px 2px;
  font-weight: 500;
  background: #F4D900;
  border-radius: 4px;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    /* Outer soft shadow */
    -1px -1px 2px 0px #FFFFFFE5,
    /* Outer highlight */
    1px -1px 2px 0px #B2B2B233,
    /* Outer subtle top-right shadow */
    -1px 1px 2px 0px #B2B2B233,
    /* Outer subtle bottom-left shadow */
    -1px -1px 2px 0px #B2B2B280 inset,
    /* Inner top-left shadow */
    1px 1px 2px 0px #FFFFFF4D inset;
  /* Inner bottom-right shadow */
}

.boundary-overball {
  min-width: 30px;
  min-height: 30px;
  font-weight: 500;
  background: #00DB00;
  border-radius: 4px;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}

.tab-button {
  background-color: none !important;

  &.active {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    color: white !important;
    background: linear-gradient(180deg, #3E119E -60.91%, #35127D 221.1%);
    box-shadow:
      0px 1px 5px 0px #180C40,
      0px 5.72px 5.72px 0px #574CD2 inset,
      0px -5.72px 5.72px 0px #281694 inset;
  }
}

.score-player-change-btn{
  color: #FFFFFF;
  border: none !important;
  padding: 0px 5px !important;
  border-radius: 100%;
  background: linear-gradient(180deg, #3E119E -19.7%, #35127D 125.76%);
  box-shadow:
    0px 1px 5px 0px #180C40,
    0px 5.72px 5.72px 0px #574CD2 inset,
    0px -5.72px 5.72px 0px #281694 inset;
}

.score-player-dropdown__indicator-separator, .css-1u9des2-indicatorSeparator{
  display: none !important;
}

.wicket-modal-player-row, .wicket-modal-header-row {
  color: $light-font-color
}

.score-control-confirm-ball-btns {
  width: 100%;
  background-color: #00B400 !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F5F5F5;
  border: none;
  font-weight: bold;
  font-size: 18px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
    &:hover,
      &:active,
      &.active{
        background-color: #FFFFFF !important;
        color: #00DB00 !important;
        box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
      }

      @media (max-width: 640px) {
        font-size: 18px;  // Reduce font size
        padding: 6px 6px;  // Adjust padding
        border-radius: 30px;  // Adjust border radius
      }
}

.score-control-conformation-close-btn {
  width: 100%;
  color: #1A1D23 !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  border: none;
  font-weight: bold;
  font-size: 18px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow:
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}


.score-player-dropdown {
  &__control {
    border: 0.5px solid #999999 !important;
    background-color: #f5f5f5 !important;
    color: #999999 !important;
    width: 100% !important;
    border-radius: 200px !important;
  }
}

.runs-input {
  border: 0.5px solid #999999 !important;
  background-color: #F5F5F5 !important;
  color: #999999 !important;
  width: 100% !important;
  border-radius: 200px !important;
  padding: 12px !important;
  @media (max-width: 640px) {
    // font-size: 18px;  // Reduce font size
    padding: 6px 6px !important;  // Adjust padding
    border-radius: 30px;  // Adjust border radius
  }
}

.score-player-dropdown {

  &__indicator,
  &__dropdown-indicator,
  &__indicatorContainer {
    box-shadow:
      1px 1px 3px 0px #b2b2b2e5,
      -1px -1px 2px 0px #ffffffe5,
      1px -1px 2px 0px #b2b2b233,
      -1px 1px 2px 0px #b2b2b233,
      -1px -1px 2px 0px #b2b2b280 inset,
      1px 1px 2px 0px #ffffff4d inset;
    margin: 4px;
    border-radius: 100px;
    background: #e6e6e6;
    color: #262a32;
    opacity: 1;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




.score-player-dropdown {
  &__menu {
    background-color: #FFFFFF !important; // Example: Set menu background to white
    color: #1A1D23 !important; // Example: Set text color to black
    // z-index: 10 !important;
  }
}

.react-switch-handle {
  background-color: #F5F5F5 !important;
  box-shadow:
    -3px -3px 2px 0px #D1D1D180 inset;
}


.react-switch-bg {
  // background-color: $toggle-light-color !important;
  // color: $toggle-dark-color;
  box-shadow:
    -1px -1px 2px 0px #0C0E1080 inset,
    /* Top-left inner shadow */
    1px 1px 2px 0px #282C364D inset;
  /* Bottom-right inner shadow */
}
.commentary-right-panel{
  padding: 8px;
  margin: 8px 0px;
  border-radius: 8px;
  background-color: $light-score-card-bg !important;
  box-shadow: 
    1px 1px 3px 0px #B2B2B2E5,
    -1px -1px 2px 0px #FFFFFFE5,
    1px -1px 2px 0px #B2B2B233,
    -1px 1px 2px 0px #B2B2B233,
    -1px -1px 2px 0px #B2B2B280 inset,
    1px 1px 2px 0px #FFFFFF4D inset;

}

.control-title{
  color: #000000 !important;
}

body[data-theme="dark"] {
  .control-title{
    color: $dark-font-color !important;
  }
  .event-date{
    color: $dark-font-color !important
  }
  
  .commentary-right-panel{
    background-color: $dark-score-card-bg !important;
    backdrop-filter: blur(80px); /* Adds a strong blur effect to the background */

    box-shadow: 
        0px 4px 32px 0px #00000080, /* Outer shadow - Large soft shadow */
        -1px -4px 8px 0px #00000040 inset; /* Inner shadow - Adds depth inside the element */

  }
  .commentary-body{
    background-color: $dark-score-body-bg !important;
  }
  .react-switch-bg {
    // background-color: #1A1D23 !important;
    box-shadow:
      -1px -1px 2px 0px #0C0E1080 inset,
      /* Top-left inner shadow */
      1px 1px 2px 0px #282C364D inset;
    /* Bottom-right inner shadow */
  }

  .score-header-event-name {
    color: #fff;
  }

  .score-header-exit-btn {
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;
  }

  .score-header-date-btn {
    background: #191C22 !important;
    border: 0.5px solid #3D3D3D;
  }

  .score-control-ball-btn {
    background-color: #1A1D23;
    color: #FFFFFF !important;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;

    &:hover {
      // box-shadow:
      //   1px 1px 3px 0px #B2B2B2E5,
      //   -1px -1px 2px 0px #FFFFFFE5,
      //   1px -1px 2px 0px #B2B2B233,
      //   -1px 1px 2px 0px #B2B2B233,
      //   -1px -1px 2px 0px #B2B2B280 inset,
      //   1px 1px 2px 0px #FFFFFF4D inset;
      background-color: #FFFFFF;
      color: #1A1D23 !important;
      box-shadow:
        0.89px 3.57px 3.57px 0px #0000001F inset,
        0px 3.57px 3.57px 0px #00000040 inset;
    }
  }

  .latency-btn{
    background: #191C22;
    color: $dark-font-color !important;
    padding: 6px;
    border: none;
  }
  .latency-speed{
    color: #00BD35 !important;
  }

  .score-header-navigation-btns {
    background-color: #1A1D23;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;
  }
  .scorecard-team-details{
    font-size: 26px;
  }

  .scorecard-striker-player-name{
    color: $dark-font-color !important;
    // color: $dark-font-color;
  }
  .scorecard-nonstriker-player-name{
    color: #8a8a8a!important;
    // color: $dark-font-color;
  }
  

  .back-icon {
    color: #fff !important;
  }

  .control-center-back-btn {
    background-color: #1A1D23;
    color: #fff !important;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;
      &:hover{
        background: #E0E0E0 !important;
      }
  }

  .score-control-ball-types-btns {
    background-color: #1A1D23;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;

    &:hover,
    &:active,
    &.active {
      background-color: #FFCD4C;
      color: #1A1D23 !important;
      box-shadow: 0px 3.57px 3.57px 0px #0000001F inset,
        0px 3.57px 3.57px 0px #00000040 inset;
    }
  }

  .score-wheel-btns {
    background-color: #1A1D23;
    color: #FFFFFF !important;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;

    &:hover,
    &:active,
    &.active {
      background-color: #FFCD4C;
      color: #1A1D23 !important;
      box-shadow: 0px 3.57px 3.57px 0px #0000001F inset,
        0px 3.57px 3.57px 0px #00000040 inset;
    }
  }

  .score-control-action-ball-btns {
    background-color: #1A1D23;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;

    &:hover,
    &:active,
    &.active {
      background-color: #009900 !important;
      color: #1A1D23 !important;
      box-shadow:
        0.89px 3.57px 3.57px 0px #0000001F inset,
        0px 3.57px 3.57px 0px #00000040 inset;
    }
  }

  .score-control-wicket-ball-btns {
    background-color: #1A1D23;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;
    color: white;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover,
    &:active,
    &.active {
      background-color: #FF4C4C;
      color: black !important;
      box-shadow:
        0.89px 3.57px 3.57px 0px #0000001F inset,
        0px 3.57px 3.57px 0px #00000040 inset;
    }
  }

  .score-control-action-btns {
    background-color: #1A1D23;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;

    &:hover,
    &:active,
    &.active {
      background-color: #009900 !important;
      color: #1A1D23 !important;
      box-shadow:
        0.89px 3.57px 3.57px 0px #0000001F inset,
        0px 3.57px 3.57px 0px #00000040 inset;
    }

  }

  .score-control-close-btn {
    background-color: #1A1D23;
    color: #fff !important;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;

  }

  .vertical-line {
    border: 0.5px solid #0C0E10E5;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;
  }

  .tab-button {
    background-color: none !important;

    &.active {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      color: white !important;
      background: linear-gradient(180deg, #3E119E -60.91%, #35127D 221.1%);
      box-shadow:
        0px 1px 5px 0px #180C40,
        0px 5.72px 5.72px 0px #574CD2 inset,
        0px -5.72px 5.72px 0px #281694 inset;
    }
  }

  .regular-overball {
    color: #fff;
    background: linear-gradient(180deg, #1C1C1C -60.91%, #1E1E1E 221.1%);
    box-shadow:
      0px 1px 5px 0px #171717,
      0px 5.72px 5.72px 0px #303030 inset,
      0px -5.72px 5.72px 0px #000000 inset;
  }

  .wicket-overball {
    color: #fff;
    background: #D90000;
    box-shadow:
      0px 1px 5px 0px #171717,
      0px 5.72px 5.72px 0px #FF9999 inset,
      0px -5.72px 5.72px 0px #4D0000 inset;
  }

  .extra-overball {
    color: #fff;
    background: #F4D900;
    box-shadow:
      0px 1px 5px 0px #171717,
      0px 5.72px 5.72px 0px #FFF399 inset,
      0px -5.72px 5.72px 0px #4D4400 inset;
  }

  .boundary-overball {
    color: #fff;
    background: #00DB00;
    box-shadow:
      0px 1px 5px 0px #171717,
      0px 5.72px 5.72px 0px #88FF88 inset,
      0px -5.72px 5.72px 0px #004700 inset;
  }

  .score-control-confirm-ball-btns {
    box-shadow:
      2.68px 2.68px 7.15px 0px #0C0E10E5,
      -2.68px -2.68px 5.36px 0px #282C36E5,
      2.68px -2.68px 5.36px 0px #0C0E1033,
      -2.68px 2.68px 5.36px 0px #0C0E1033,
      -0.89px -0.89px 1.79px 0px #0C0E1080 inset,
      0.89px 0.89px 1.79px 0px #282C364D inset;
      &:hover,
      &:active,
      &.active{
        background-color: #FFFFFF !important;
        color: #00DB00 !important;
        box-shadow:
          3px 3px 8px 0px #191d21e5,
          -3px -3px 6px 0px #3d4353e5,
          3px -3px 6px 0px #2d353d33,
          -3px 3px 6px 0px #4d596533,
          -1px -1px 2px 0px #0C0E1080 inset,
          1px 1px 2px 0px #282C364D inset;
      }
  }

  .score-control-conformation-close-btn {
    background-color: #1A1D23 !important;
    color: #fff !important;
    box-shadow:
      2.68px 2.68px 7.15px 0px #0C0E10E5,
      -2.68px -2.68px 5.36px 0px #282C36E5,
      2.68px -2.68px 5.36px 0px #0C0E1033,
      -2.68px 2.68px 5.36px 0px #0C0E1033,
      -0.89px -0.89px 1.79px 0px #0C0E1080 inset,
      0.89px 0.89px 1.79px 0px #282C364D inset;

  }

  .score-player-dropdown {
    &__control {
      box-shadow:
        -0.89px -0.89px 1.79px 0px #0C0E1080 inset,
        0.89px 0.89px 1.79px 0px #282C364D inset;
      background-color: #1A1D23 !important;
      color: #999999 !important;
    }
  }

  .runs-input {
    box-shadow:
      -0.89px -0.89px 1.79px 0px #0C0E1080 inset,
      0.89px 0.89px 1.79px 0px #282C364D inset;
    background-color: #1A1D23 !important;
    color: #999999 !important;
  }

  .score-player-dropdown {

    &__indicator,
    &__dropdown-indicator,
    &__indicatorContainer {
      box-shadow:
        2.68px 2.68px 7.15px 0px #0C0E10E5,
        -2.68px -2.68px 5.36px 0px #282C36E5,
        2.68px -2.68px 5.36px 0px #0C0E1033,
        -2.68px 2.68px 5.36px 0px #0C0E1033,
        -0.89px -0.89px 1.79px 0px #0C0E1080 inset,
        0.89px 0.89px 1.79px 0px #282C364D inset;
      background: #1A1D23;
      color: #FFFFFF;
    }
  }

  .select2-selection__input-container,
  .css-19bb58m {
    color: #FFFFFF;
  }

  .select2-selection__menu,
  .css1nmdiq5-menu {
    background-color: #1A1D23;
  }

  .score-player-dropdown {
    &__value-container {
      color: #FFFFFF !important; // Sets text color to white

      &--has-value {
        color: #FFFFFF !important; // Ensures color remains white when a value is selected
      }
    }
  }

  .score-player-dropdown {
    &__single-value {
      color: #FFFFFF !important; // Ensures the selected value text is white
    }
  }

  .score-player-dropdown {
    &__menu {
      background-color: #1A1D23 !important; // Example: Set menu background to white
      color: #FFFFFF !important; // Example: Set text color to black
      z-index: 10 !important;
    }
  }

  .score-player-dropdown {
    &__option {
      &:hover {
        background-color: #39424f !important;
      }

      &--is-focused {
        background-color: #39424f !important;
      }

      &--is-selected {
        background-color: #0056b3 !important; // Darker blue for selected option
        color: #ffffff !important;
      }
    }
  }

  .player-names {
    background-color: #1A1D23;
    color: #FFFFFF !important;
    box-shadow:
      3px 3px 8px 0px #0C0E10E5,
      -3px -3px 6px 0px #282C36E5,
      3px -3px 6px 0px #0C0E1033,
      -3px 3px 6px 0px #0C0E1033,
      -1px -1px 2px 0px #0C0E1080 inset,
      1px 1px 2px 0px #282C364D inset;
    &:hover{
      box-shadow:
        3px 3px 8px 0px #191d21e5,
        -3px -3px 6px 0px #3d4353e5,
        3px -3px 6px 0px #2d353d33,
        -3px 3px 6px 0px #4d596533,
        -1px -1px 2px 0px #0C0E1080 inset,
        1px 1px 2px 0px #282C364D inset;
    }
  }

  .react-switch-handle {
    background-color: #1A1D23 !important;
    box-shadow: 
    0px 0px 0px 0px rgba(12, 14, 16, 0.9), 
    0px 0px 0px 0px rgba(40, 44, 54, 0.9), 
    0px -0px 0px 0px rgba(12, 14, 16, 0.2),
    0px 0px 0px 0px rgba(12, 14, 16, 0.2), 
    -1px -1px 2px 0px rgba(12, 14, 16, 0.5) inset, /* Inner shadow (top-left) */
    1px 1px 2px 0px rgba(40, 44, 54, 0.3) inset;  /* Inner shadow (bottom-right) */
  
  }
}